import { createTheme } from "@mui/material/styles";


export const lightTheme = createTheme({
    palette: {
        mode: 'light', //default theme
        primary: {
            main: '#FFFFFF',
            light: '#FFFFFF',
            cardColor: 'rgba(255,255,255,0.04)',
            contactCardColor: 'rgba(12,16,38,0.24)'
        },
        secondary: {
            main: '#01082E',
            light: '#272D53',
        },
        gray: {
            main: '#615D5D'
        },
        white: 'white',
        black: 'black',
    }
});