import * as React from 'react';
import MUIButton from '@mui/material/Button';

export default function StandardButton(props) {
    return <MUIButton 
    variant="contained"             
    target={(props.href.startsWith('https') ? '_blank' : "_self")}
    href={props.href} sx={{
        height: '40px',
        width: '200px',
        borderRadius: '20px',
        fontFamily: 'montserrat',
        fontSize: '15px',
        textTransform: 'none',
        boxShadow: 'none',
        ...props.sx
    }}>{props.content}</MUIButton>;
}