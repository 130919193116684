import * as React from 'react';
import { useTheme } from '@mui/material/styles';

export default function CardTitle(props) {

    const theme = useTheme();
    return (
    <p style={{
        fontWeight: 800,
        textTransform: 'uppercase',
        fontSize: "20px",
        color: theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.secondary.light
    }}>
        {props.content}
    </p>
    );
}