import * as React from 'react';
import useWindowDimensions from '../../../assets/hook/WindowsDimensionsHook';

export default function ProjectLogo({logoPath}) {
    return (
        <div style={{
            height: '60px',
            margin: '18px 0 20px auto',
            textAlign: (useWindowDimensions().width > 900 ? 'right' : 'center')
        }}>
            <img 
                style={{maxHeight:'100%', maxWidth: '100%'}} 
                src={logoPath}
                alt="">
            </img>
        </div>
    );
}