// import { useRouteError } from "react-router-dom";
import ErrorPicture from "../../assets/img/pictures/errorpage_logo.png";
import StandardButton from "../atoms/Button/StandardButton";
import { useTheme } from "@emotion/react";

export default function ErrorPage() {
	// const error = useRouteError();
	const theme = useTheme();

	return (
		<div id="error-page"
		style={{
			width: '100vw',
			height: '100vh',
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
			alignItems:"center"
		}}>
			<h1>Oops!</h1>
			<img style={{
				width: "30vw",
				maxWidth: '700px'
			}} src={ErrorPicture} alt="Error"></img>
			<p>La page demandée est introuvable.</p>
			<StandardButton href={'/'} sx={{
				backgroundColor: theme.palette.primary.light
			}}content="Revenir à l'accueil"></StandardButton>
		</div>
	);
}