import useWindowDimensions from "../../assets/hook/WindowsDimensionsHook";
import Background from "../molecules/Background/Background";
import ProjectInformation from "../organisms/ProjectInformation";
import ProjectPresentation from "../organisms/ProjectPresentation";
import { useParams } from "react-router-dom";

export default function ProjectPage() {
    const { projectSlug } = useParams();
    const ProjectData =require('../../data/'+projectSlug+'.json');
    document.title = projectSlug.charAt(0).toUpperCase() + projectSlug.slice(1) +" | Mostacci Grégory"
    return (
        <>
            <Background></Background>
            <main style={{
                display:"flex",
                justifyContent: 'space-between',
                height: '90vh',
                flexDirection: (useWindowDimensions().width > 1600 ? "row" : "column"),
            }}>
                <ProjectInformation data={ProjectData}></ProjectInformation>
                <ProjectPresentation data={ProjectData}></ProjectPresentation>
            </main>
        </>
        
    );
}