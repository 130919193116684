import { useTheme } from "@emotion/react";

export default function ProjectFeatures({features}) {
    const theme = useTheme();
    return (
        <div style={{
            marginTop: '80px'
        }}>
            <h3 style={{
                color: theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.secondary.main,
            }}>
                Fonctionnalités in-app
            </h3>
            <ul style={{
                color: theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.secondary.main,
                fontSize: '17px',
                lineHeight: '25px',
                textAlign: "left"
            }}>
                {features.map((feature, index) => {
                    return (
                        <li key={index}>{feature}</li>
                    )
                })}
                
            </ul>
        </div>
    );
}