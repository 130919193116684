import { createTheme } from "@mui/material/styles";


export const darkTheme = createTheme({
    palette: {
        mode: 'dark', //default theme
        primary: {
            main: '#01082E',
            light: '#272D53',
            cardColor: 'rgba(12,16,38,0.24)',
            contactCardColor: 'rgba(154,35,92,0.24)'
        },
        secondary: {
            main: '#9A235C',
            light: '#f54e9d'
        },
        gray: {
            main: '#CDCDCD'
        },
        white: 'white',
        black: 'black',
    }
});