import useWindowDimensions from "../../../assets/hook/WindowsDimensionsHook";
import ProjectVersion from "./ProjectVersion";
import { useTheme } from "@emotion/react";

export default function ProjectTitle({title, comment, version}) {
    const theme = useTheme();
    return (
        <>
            <p style={{
                color: theme.palette.gray.main,
                fontSize: (useWindowDimensions().width > 900 ? '18px' : '15px'),
                fontStyle: 'italic',
                marginBottom:0
            }}>{comment}</p>

            <div style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <h1 style={{
                    color: theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.secondary.main,
                    fontWeight: 800,
                    fontSize: (useWindowDimensions().width > 900 ? '60px' : '40px'),
                    marginTop:0
                }}>{title}</h1>
                <ProjectVersion version={version}></ProjectVersion>
            </div>
        </>
    );
}