import * as React from "react";
import {createBrowserRouter} from "react-router-dom";
import HomePage from "../components/pages/HomePage";
import ErrorPage from "../components/pages/ErrorPage";
import ProjectPage from "../components/pages/ProjectPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomePage/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/:projectSlug",
        element: <ProjectPage></ProjectPage>,
        errorElement: <ErrorPage/>
    }
]);

export default router;