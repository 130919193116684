import * as React from 'react';
import ContactSquare from '../../atoms/ContactSquare/ContactSquare';
import MailIcon from '../../../assets/img/icons/mail.png'

export default function ContactMail(props) {
    return (
        <ContactSquare href='mailto:mostacci.gregory@gmail.com'>
            <img style={{
                marginTop: '8px'
            }}width='25px' src={MailIcon} alt="" />
        </ContactSquare>
    );
}