import BackgroundColor from "../../atoms/Background/BackgroundColor";

function Background() {
    return (
        <>
            {/* <BackgroundText></BackgroundText> */}
            <BackgroundColor></BackgroundColor>
        </>
    );
}

export default Background;
