import * as React from 'react';
import ContactSquare from '../../atoms/ContactSquare/ContactSquare';
import CV from '../../../assets/files/cv.pdf'

export default function ContactCV(props) {
    return (
        <ContactSquare href={CV} download='MOSTACCI_Grégory_Développeur_Curriculum_Vitae.pdf'>
            CV
        </ContactSquare>
    );
}