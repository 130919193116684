import * as React from 'react';
import StandardButton from './StandardButton';
import { useTheme } from '@mui/material/styles';

export default function ContactButton(props) {
    const theme = useTheme();

    return (<StandardButton 
        href='mailto:mostacci.gregory@gmail.com'
        variant="contained"
        content={props.content} 
        sx={{
            color: theme.palette.mode === 'dark' ? theme.palette.primary.light: theme.palette.white,
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.secondary.light
        }}></StandardButton>
    );
}