import * as React from 'react';

export default function CardLogo(props) {
    return (
        <div style={{
            maxHeight: '60px',
            display: 'flex',
            textAlign: 'center',
            maxWidth: "80%"
        }}>
            <img 
                style={{maxWidth: "100%", maxHeight: "100%"}} 
                src={props.picture}
                alt="">
            </img>
        </div>
    );
}