export default function ProjectScreenList(props) {
    return (
        <div style={{
            display: "flex",
            maxWidth: '850px',
            overflowX: 'auto'
        }}>
            {props.screens.map((image, index) => {
                return (
                    <div className={"img"+index} key={index}>
                        <img src={image} alt="" style={{
                            ...(index > 0 ? {marginLeft: "20px"}: '')}} />
                    </div>
                );
            })}
        </div>
    );
}