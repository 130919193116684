import * as React from 'react';
import StandardButton from './StandardButton';
import { useTheme } from '@mui/material/styles';

export default function ProjectButton({href, content}) {
    const theme = useTheme();
    
    return (
        <StandardButton 
            href={href}
            content={content}
            sx={{
                color: (theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.secondary.light),
                backgroundColor: (theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.white)
            }}
        ></StandardButton>
    );
}