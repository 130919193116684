import { useTheme } from "@emotion/react";
import ProjectButton from "../../atoms/Button/ProjectButton";
import CardLogo from "../../atoms/Card/CardLogo";
import CardText from "../../atoms/Card/CardText";
import CardTitle from "../../atoms/Card/CardTitle";
import CardSkills from "../../atoms/Card/CardSkills";

export default function Card({ picture, title, description, slug, skills, externalLink }) {
    const theme = useTheme();
    const href = externalLink ? externalLink: ("/"+slug);
    
    return (
        <div style={{
            minWidth: "280px",
            maxWidth: "280px",
            height: "450px",
            border: "solid 2px "+ (theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.white),
            borderRadius: "20px",
            backgroundColor: theme.palette.primary.cardColor,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "20px",
            boxSizing: "padding-box",
        }}>
            <CardLogo picture={picture}></CardLogo>
            <div style={{ textAlign:"center" }}>
                <CardTitle content={title}></CardTitle>
                <CardText content={description}></CardText>
            </div>
            <CardSkills list={skills}></CardSkills>
            <ProjectButton href={href} content="Consulter"></ProjectButton>
        </div>
    );
}