import * as React from 'react';
import ContactSquare from '../../atoms/ContactSquare/ContactSquare';
import LinkedinIcon from '../../../assets/img/icons/linkedin.png'

export default function ContactLinkedin(props) {
    return (
        <ContactSquare href='https://www.linkedin.com/in/gregory-mostacci/'>
            <img width='25px' src={LinkedinIcon} alt="" />
        </ContactSquare>
    );
}