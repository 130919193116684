import useWindowDimensions from "../../assets/hook/WindowsDimensionsHook";
import ContactCV from "../molecules/Contact/ContactCV";
import ContactLinkedin from "../molecules/Contact/ContactLinkedin";
import ContactMail from "../molecules/Contact/ContactMail";

function ContactBlocks() {
    return (
        <div style={{
            display: 'flex',
            marginBottom: (useWindowDimensions().width > 900 ? "40px" : "20px"),
            justifyContent: (useWindowDimensions().width > 900 ? "left" : "center")
        }}>
            <ContactMail></ContactMail>
            <ContactLinkedin></ContactLinkedin>
            <ContactCV></ContactCV>
        </div>
    );
}

export default ContactBlocks;