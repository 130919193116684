import { useTheme } from "@emotion/react";
import useWindowDimensions from "../../../assets/hook/WindowsDimensionsHook";

export default function ProjectDescription({children}) {
    const theme = useTheme();
    return (
        <p style={{
            color: theme.palette.gray.main,
            fontSize: (useWindowDimensions().width > 900 ? '17px' : '15px'),
            maxWidth: '700px',
            textAlign: 'justify',
            textIndent: '30px',
            fontStyle: 'italic',
            marginTop: 0
        }}>{children}</p>
    );
}