import ContactButton from "../../atoms/Button/ContactButton";
import CardText from "../../atoms/Card/CardText";
import CardTitle from "../../atoms/Card/CardTitle";
import { useTheme } from '@mui/material/styles';

export default function ContactCard(props) {
    const theme = useTheme();

    return (
        <div style={{
            minWidth: "280px",
            maxWidth: "280px",
            height: "450px",
            border: "solid 2px "+ (theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.secondary.light),
            borderRadius: "20px",
            backgroundColor: theme.palette.primary.cardColor,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "20px",
            boxSizing: "padding-box"
        }}>
            <div style={{height: '50px'}}></div>
            <div style={{ textAlign:"center" }}>
                <CardTitle type="contact" content="Une idée, un projet ?"></CardTitle>
                <CardText type="contact" content="Echangeons autour des sujets qui vous préoccupent."></CardText>
            </div>
            <div style={{height: "40px"}}></div>
            <ContactButton content="Me contacter"></ContactButton>
        </div>
    );
}