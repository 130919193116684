import ProjectCard from "../molecules/Card/ProjectCard";
import cards from '../../data/cards.json'
import ContactCard from "../molecules/Card/ContactCard";
import { useTheme } from "@emotion/react";
import useWindowDimensions from "../../assets/hook/WindowsDimensionsHook";

function ProjectList() {
    const theme = useTheme();
    const marge = useWindowDimensions().width > 900 ? 7 : 6;
    const gridColumns = Math.round(useWindowDimensions().width / 10 * marge / 280);
    return (
        <>
            <p style={{
                color: theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.secondary.light, 
                fontSize: '18px',
            }}>Mes projets</p> 

            <div style={{
                display:"grid",
                maxWidth: '1800px',
                gridTemplateColumns: "repeat("+gridColumns+", 1fr) ",
                alignItems: "center",
                rowGap: "20px",
                columnGap: "20px"
            }}>
                {cards.map((card) => {
                    return (
                        <ProjectCard
                            picture={card.picture}
                            title={card.title}
                            description={card.description}
                            slug={card.slug}
                            externalLink={card.externalLink}
                            skills={card.skills}
                            key={card.slug}
                        ></ProjectCard>
                    );
                })}
                <ContactCard></ContactCard>
            </div>
        </>
    );
}

export default ProjectList;