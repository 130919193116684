export default function CardSkills({list}){
    return (
        <div className="skills" style={{
            display: 'flex',
            alignItems: "center",
            justifyContent: "space-around",
            flexWrap: 'wrap'
        }}>
            {list.map((skill, index) => {
                return (
                    <img key={{index}} style={{
                        margin: "5px",
                        maxHeight: '40px',
                        height: 'auto',
                        maxWidth: '50px',
                        width: 'auto',
                    }}
                    src={"/assets/img/skills/" + skill + ".png"} alt="" />
                )
            })}
        </div>
    )
}