import * as React from 'react';
import { useTheme } from '@mui/material/styles';

export default function TextCard(props) {
    const theme = useTheme();

    return (<p
        style={{
            fontSize: "17px",
            color: theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.secondary.light,
            height: '60px'
        }}> 
            {props.content}
        </p>
    );
}