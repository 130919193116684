import { useTheme } from '@emotion/react';
import { Link } from '@mui/material';
import * as React from 'react';

export default function ContactSquare({href, download, children, sx}) {
    const theme = useTheme();
    const style = {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '10px',
        minWidth: '45px',
        height: '45px',
        textAlign: 'center',
        alignContent: 'center',
        margin: '0 20px 0 0',
        padding: 0,
        color:'white',
        fontSize: '20px',
        fontWeight: 800,
        textDecoration: 'none',
        userSelect: 'none',
        ...sx
    }
    return (
        <Link 
            target='_blank' 
            href={href} 
            {...(download) ? {download:download} : {}}style={style}>
            {children}
        </Link>
    );
}