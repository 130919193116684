
import ProjectDescription from "../molecules/Project/ProjectDescription";
import ProjectFeatures from "../molecules/Project/ProjectFeatures";
import ProjectTitle from "../molecules/Project/ProjectTitle";

export default function ProjectInformation({data}) {
    return (
        <div>
            <ProjectTitle 
                comment={data.comment}
                title={data.title}
                version={data.version}>
            </ProjectTitle>
            <ProjectDescription>{data.description}</ProjectDescription>
            <ProjectFeatures features={data.features}></ProjectFeatures>
        </div>
    );
}