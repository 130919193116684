import { useTheme } from "@emotion/react";

export default function ProjectVersion({version}) {

    const theme = useTheme();
    return (
        <p style={{
            color: theme.palette.gray.main,
            margin: 0,
            marginLeft: '20px'
        }}>v{version}</p>
    );
}