import AndroidButton from "../../../assets/img/icons/android_download.png";
import AppleDownload from "../../../assets/img/icons/appstore_download.png";
import WebAccess from "../../../assets/img/icons/web_access.png";
import { Link } from "react-router-dom";

export default function ProjectDownloadButton({type, href}) {
    
    let img = '';
    if(type === 'android')
        img = AndroidButton;
    else if(type === 'ios')
        img = AppleDownload;
    else if(type === 'web')
        img = WebAccess;
    
    return (
        <Link to={href} target="_blank" sx={{
            padding: 0,
            marginLeft: '10px'
        }}>
            <img 
            src={img}
            alt="" 
        />
        </Link>
    );
}