function BackgroundColor() {
    const style = {
        minHeight: '100%',
        minWidth: '100vw',
        position:'absolute',
        backgroundImage: 'url(assets/img/background.png)'
    }
    return (
        <>
        <div style={style}></div>
        <div style={style}></div>
        </>
    );
}

export default BackgroundColor;
