import './fonts.css';
import './App.css';
import {RouterProvider} from "react-router-dom";
import router from './routes/AppRoutes';
import React, { useEffect, useState } from "react";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Switch } from '@mui/material';
import { lightTheme } from './components/themes/LightTheme';
import { darkTheme } from './components/themes/DarkTheme';
import useWindowDimensions from './assets/hook/WindowsDimensionsHook';

function App() {

	const [theme, setTheme] = useState( (localStorage.getItem("theme") ?? "light"));
	const sizes = useWindowDimensions();

	useEffect(() => {
		localStorage.setItem('theme', theme);
	  }, [theme]);

	const toggleTheme = () => {
		setTheme((current) => (current === "light" ? "dark" : "light"));
	};

	if(theme === 'dark')
		document.querySelector('body').classList.add('dark')
	else
		document.querySelector('body').classList.remove('dark')
	
    return (
		<ThemeProvider theme={(theme === 'dark' ? darkTheme : lightTheme)}>
      		<CssBaseline />
			<React.StrictMode>
				<RouterProvider router={router} />
				<Switch color="white" sx={{
					position: 'absolute',
					top: sizes.width > 900 ? '50px' : '10px',
					right: sizes.width > 900 ? '100px' : '10px',
					zIndex: 2,
				}} checked={theme === 'dark'} onChange={toggleTheme} />
			</React.StrictMode>
		</ThemeProvider>
    );
}

export default App;
