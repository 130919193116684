import useWindowDimensions from "../../assets/hook/WindowsDimensionsHook";
import ProjectDownloadButton from "../molecules/Project/ProjectDownloadButton";
import ProjectLogo from "../molecules/Project/ProjectLogo";
import ProjectScreenList from "../molecules/Project/ProjectScreenList";

export default function ProjectPresentation({data}) {
    
    return (
        <div style={{
            display: 'flex',
            flexDirection: "column",
            justifyContent: "space-between"
        }}>
            <div>
                <ProjectLogo logoPath={data.logoPath}></ProjectLogo>
                <div style={{
                    display: 'flex',
                    justifyContent: (useWindowDimensions().width > 900 ? "right": "center"),
                }}>
                    {data.availableOn.map((element, index) => {
                    return (
                        <ProjectDownloadButton href={element.link} key={index} type={element.type}></ProjectDownloadButton>
                    ); 
                })}
                </div>
            </div>
            <ProjectScreenList screens={data.screenList}></ProjectScreenList>
        </div>
    );
}